<template>
  <v-container>
    <v-progress-linear
      v-if="loading"
      indeterminate
    />
    <v-row justify="center">
      <v-col md="12" lg="6">
        <v-card v-if="company">
          <ViewCompany :company="company" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ViewCompany from '@/components/Companies/ViewCompany'

export default {
  components: { ViewCompany },
  data: () => ({
    loading: true,
    company: null
  }),
  created () {
    this.fetchData()
  },
  methods: {
    fetchData() {
      if (!this.$store.state.auth.user.company) {
        this.loading = false
        this.$store.commit('alert/add', {
          color: 'error',
          text: "Votre compte n'est pas encore lié à une entreprise"
        })
        return
      }
      this.$store.dispatch(
        'companies/fetchOne',
        this.$store.state.auth.user.company.id
      ).then(res => {
        console.debug(res)
        this.loading = false
        this.company = res
      })
    }
  }
}
</script>
